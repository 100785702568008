<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('PROFILEPUSH' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [iconClass]="'fs-5'" [text]="('PROFILEPUSHTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>
  <ng-template #content>

    <div class="d-flex flex-column h-100 p-3">
      <div class="custom-scrollbar overflow-y-auto">
        <!-- Custom Settings Section -->
        <div class="row mb-3">
          <div class="col-12">
            <app-form-label [label]="'GLOBALSETTINGS' | translate"></app-form-label>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 d-flex align-items-center">
                <span class="me-auto pe-2 text-muted">
                  <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEALL' | translate"></app-form-label>
                </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profilePushForm" [fCN]="'push_disable_all'"></app-form-switch>
              </div>
            </div>
          </div>
        </div>

        <!-- Custom Settings Section -->
        <div class="row align-items-center mb-3">
          <div class="col-12">
            <app-form-label [label]="'CUSTOMSETTINGS' | translate"></app-form-label>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 col-md-6 d-flex align-items-center">
                <span class="me-auto pe-2 text-muted">
                  <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEMOBILE' | translate"></app-form-label>
                </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profilePushForm" [fCN]="'push_disable_mobile'"></app-form-switch>
              </div>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
                <span class="me-auto pe-2 text-muted">
                  <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEEMAIL' | translate"></app-form-label>
                </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profilePushForm" [fCN]="'push_disable_email'"></app-form-switch>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-2">
            <div class="col-12 col-md-6 d-flex align-items-center">
                <span class="me-auto pe-2 text-muted">
                  <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLEWEBSITE' | translate"></app-form-label>
                </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profilePushForm" [fCN]="'push_disable_browser'"></app-form-switch>
              </div>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
                <span class="me-auto pe-2 text-muted">
                  <app-form-label [type]="'content'" [label]="'PUSHSETTINGDISABLECHAT' | translate"></app-form-label>
                </span>
              <div class="flex-shrink-0">
                <app-form-switch [fG]="profilePushForm" [fCN]="'push_disable_chat'"></app-form-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" (click)="save()" [cssClass]="'btn btn-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
