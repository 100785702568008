import {Injectable} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {TYPE} from "../types/types";
import {ApiService} from "./api.service";
import {HttpEvent, HttpEventType, HttpHeaderResponse} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public supportedImageTypes: string[] = ['gif', 'jpg', 'jpeg', 'png'];
  private fileSubscription: Subscription[] = [];

  constructor(
    private apiService: ApiService
  ) {
  }

  public uploadDocuments(folder: string, documents: FileList, multiSelect: boolean = true, allowedType: string[] = []): Observable<TYPE.Document[]> {
    return new Observable<TYPE.Document[]>((observer) => {
      if (!folder) {
        observer.error({code: 400, message: 'DOCUMENTSNOFOLDER'});
      } else if (documents.length === 0) {
        observer.error({code: 400, message: 'DOCUMENTSNOFILES'});
      } else if (documents.length > 1 && !multiSelect) {
        observer.error({code: 400, message: 'DOCUMENTSTOOMANYFILES'});
      } else if (allowedType.length > 0 && this.checkType(documents, allowedType)) {
        observer.error({code: 400, message: 'DOCUMENTSINVALIDTYPE'});
      } else {
        // convert FileList to Array of TYPE.Document to store additional information
        let returnArray: TYPE.Document[] = Array.from(documents).map((document) => {
          return {localFile: document} as TYPE.Document;
        });

        returnArray.forEach((document, index) => {
          // preview images and videos
          if (document && (document.localFile?.type.startsWith('image/') || document.localFile?.type.startsWith('video/'))) {
            this.previewDocument(document);
          }

          // upload files
          this.fileSubscription[index] = this.apiService.uploadFile(
            document.localFile!,
            folder
          ).subscribe((event: HttpEvent<any>) => {
            if (event instanceof HttpHeaderResponse) {
              switch (event.status) {
                case 415: // unsupported media type
                  returnArray[index].error = event.statusText;
                  break;
                case 500: // internal server error
                  returnArray[index].error = event.statusText;
                  break;
                case 555: // internal server error
                  returnArray[index].error = event.statusText;
                  break;
              }
            }

            switch (event.type) {
              case HttpEventType.Sent:
                returnArray[index].progress = 0;
                break;
              case HttpEventType.ResponseHeader:
                returnArray[index].progress = 0;
                break;
              case HttpEventType.UploadProgress:
                const progress = Math.round(event.loaded / event.total! * 100);
                returnArray[index].progress = progress;
                break;
              case HttpEventType.Response:
                returnArray[index].progress = 100;
                if (event.body && event.body[0]) {
                  returnArray[index] = event.body[0];
                }
            }

            observer.next(returnArray);
          });
        });
        //observer.complete();
      }
    });
  }

  private checkType(documents: FileList, allowedType: string[]): boolean {
    let foundError = false;
    Array.from(documents).forEach((document) => { // a FileList is not an array, so we convert it to one
      if (!allowedType.includes(document.name.toLowerCase().split('.').pop()!)) {
        foundError = true;
      }
    });
    return foundError;
  }

  private previewDocument(document: TYPE.Document): TYPE.Document {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      document.preview = e.target?.result as string;
    };
    reader.readAsDataURL(document.localFile!); // Base64 encoded string
    return document;
  }
}
