<form [formGroup]="fG">
  <ejs-dropdownlist
    [formControlName]="fCN"
    [popupWidth]="popupWidth"
    [dataSource]="dataSource"
    [fields]="mapping"
    [placeholder]="placeholder"
    [allowFiltering]="allowFiltering"
    [sortOrder]="sortOrder"
    [cssClass]="cssClass + ' ' + (fG.controls[fCN].invalid && fG.controls[fCN].dirty ? 'e-error' : '')"
    (change)="onChange($event)"
    (open)="checkEndOfList($event)"
    (filtering)="filter($event)">
    <ng-template *ngIf="icon" #valueTemplate let-data="">
      <div class="d-flex align-items-center text-muted">
        <app-icon-badge [style]="'plainIcon'" [icon]="icon"></app-icon-badge>
        <span class='value fs-small'>{{ (data && data.name) ? data.name : placeholder}}</span>
      </div>
    </ng-template>
    <ng-template #itemTemplate let-data="">
      <span class="{{ (marker === 'priority' && data.id === 'high') ? 'text-danger' : (marker === 'priority' && data.id === 'medium') ? 'text-warning' : (marker === 'priority' && data.id === 'low') ? 'text-success' : '' }}">{{data.name}}</span>
    </ng-template>
  </ejs-dropdownlist>
</form>
