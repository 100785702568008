<div class="custom-scrollbar">
  <ul class="steps-list list-unstyled">
    <ng-container *ngFor="let step of steps; let i = index">
      <li class="{{ i === activeStep ? 'active-step' : i < activeStep ? 'completed-step' : 'inactive-step' }}" (click)="setStep(i)">
        <span class="step-number">{{ i + 1 }}</span>
        <span class="step-title truncate-text-one-line">{{ step }}</span>
      </li>
    </ng-container>
  </ul>
</div>
