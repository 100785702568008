<!-- User/Group and External Email-->
<div class="row g-2 mb-1" *ngIf="!readonly">
  <div class="col-6">
    <div class="d-flex-column">
      <span class="text-secondary fw-bold">
        <app-form-label [type]="'content'" [label]="'ADDGROUPUSER' | translate"></app-form-label>
      </span>

      <app-form-dropdown
        [fG]="userGroupMailForm"
        [fCN]="'userGroup'"
        [dataSource]="userGroupOptions"
        [placeholder]="'TYPEUSERGROUPNAME' | translate"
        [allowFiltering]="true"
        [mapping]="{text: 'name', value: 'id'}"
        [returnObject]="true"
        (reachedEndOfList)="loadNextPage()"
        (search)="search($event)"
        (valueChange)="addUserGroup($event)">
      </app-form-dropdown>
    </div>
  </div>

  <div class="col-6" *ngIf="allowEmails">
    <div class="d-flex-column">
      <span class="text-secondary fw-bold">
        <app-form-label [type]="'content'" [label]="'EXTERNALMAIL' | translate"></app-form-label>
      </span>
      <app-form-input [fG]="userGroupMailForm" [fCN]="'addMail'" [placeholder]="('EXTERNALMAIL' | translate)" [iconBack]="'add_circle'" [iconClickType]="'back'" (iconBackClick)="addMail()"></app-form-input>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <span class="text-secondary fw-bold">
      <app-form-label [type]="'content'" [label]="'SELECTEDPARTICIPANTS' | translate"></app-form-label>
    </span>
  </div>

  <div class="d-flex justify-content-between align-items-center">
    <app-form-dropdown
      [fG]="userGroupMailForm"
      [fCN]="'userGroupMailFilter'"
      [dataSource]="userGroupMailFilter"
      [placeholder]="'SELECTFILTER' | translate"
      [icon]="'filter_list'"
      [popupWidth]="'auto'"
      [cssClass]="'small-filter-dropdown'">
    </app-form-dropdown>

    <div class="dropdown">
      <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
        <app-icon-badge [style]="'notification'" [icon]="'more_vert'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="linkManagement">
        <li><app-link (click)="selectAll()" [title]="('SELECTALL' | translate)" [style]="'dropdown'">{{ ('SELECTALL' | translate) }}</app-link></li>
        <li><app-link (click)="removeSelected()" [title]="('REMOVESELECTED' | translate)" [style]="'dropdown'">{{ ('REMOVESELECTED' | translate) }}</app-link></li>
      </ul>
    </div>
  </div>
</div>

<div class="custom-scrollbar overflow-y-auto">
  <ng-container  *ngFor="let participant of participants">
    <div class="card selected-user-group mb-2" *ngIf="userGroupMailForm.controls['userGroupMailFilter'].value === 'all' || userGroupMailForm.controls['userGroupMailFilter'].value === participant.type">
      <div class="d-flex align-items-center align-items-stretch">
        <div class="remove-icon-column d-flex align-items-center justify-content-center border-end" *ngIf="!readonly">
          <app-form-button (click)="showDeleteOverlay(participant)" [cssClass]="'e-link text-muted p-0'" [icon]="'delete'"></app-form-button>
        </div>

        <div class="d-flex align-items-center px-1">
          <div class="e-avatar e-avatar-circle e-avatar-small m-1">
            <img *ngIf="participant.image" [ngSrc]="participant.image" [width]="40" [height]="40" [sizes]="'100vw, 100vw, 100vw'" [alt]="participant.name!" priority="low" />
            <app-icon-badge *ngIf="!participant.image && participant.type === 'user'" [style]="'plainIcon'" [icon]="'person'" [screenReaderText]="participant.name!"></app-icon-badge>
            <app-icon-badge *ngIf="!participant.image && participant.type === 'group'" [style]="'plainIcon'" [icon]="'group'" [screenReaderText]="participant.name!"></app-icon-badge>
            <app-icon-badge *ngIf="!participant.image && participant.type === 'mail'" [style]="'plainIcon'" [icon]="'mail'" [screenReaderText]="participant.name!"></app-icon-badge>
          </div>
        </div>

        <div class="flex-grow-1 lh-1 d-flex align-items-center">
          <app-tooltip [text]="participant.name!" [opensOn]="'Hover'">
            <app-headline [type]="'h6'" [title]="participant.name!" [class]="'text-muted fs-small'"></app-headline>
            <app-content *ngIf="participant.type === 'user'" [style]="'userGroupMail'">{{ 'USER' | translate }}</app-content>
            <app-content *ngIf="participant.type === 'group'" [style]="'userGroupMail'">{{ 'GROUP' | translate }} - <b>{{ participant.count }}</b> {{ 'USER' | translate }}</app-content>
            <app-content *ngIf="participant.type === 'mail'" [style]="'userGroupMail'">{{ 'EXTERNALMAIL' | translate }}</app-content>
          </app-tooltip>
        </div>

        <div class="push-notification-column d-flex align-items-center justify-content-center border-start px-2" *ngIf="participant.type !== 'mail' && allowPush && !readonly">
          <app-tooltip [text]="'TOGGLENOTIFICATON' | translate" [opensOn]="'Hover'">
            <app-form-button (click)="togglePush(participant)" [iconClass]="'fs-5'" [cssClass]="'e-link p-0' + (participant.push ? ' text-primary' : ' text-light')" [icon]="'stream_apps'"></app-form-button>
          </app-tooltip>
        </div>

        <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2" *ngIf="!readonly">
          <app-form-checkbox [fG]="userGroupMailForm" [fCN]="participant.type! + participant.id" [cssClass]="'pb-1'"></app-form-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
</div>

