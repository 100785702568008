import {Injectable} from '@angular/core';
import {TYPE} from "../types/types";
import {LocalStorageService} from "./local-storage.service";
import {SocketService} from "./socket.service";
import {LanguageService} from "./language.service";
import {CustomerService} from "./customer.service";
import {BehaviorSubject} from "rxjs";

class SubjectBehavior<T> {
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public iconBarVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private user: TYPE.User | null = null;
  private token: string | null = null;

  constructor(
    private localStorageService: LocalStorageService,
    private socketService: SocketService,
    private languageService: LanguageService,
    private customerService: CustomerService
  ) {
  }

  public setToken(token: string | null): void {
    this.token = token;
  }

  public getToken(): string | null {
    return this.token;
  }

  public setUser(user: TYPE.User): void {
    if (user.token) this.localStorageService.setLocalStorage('userToken', user.token);
    this.user = user;
    this.socketService.connect(user.id);
  }

  public getUser(): TYPE.User | null {
    return this.user;
  }

  public resetUser(): void {
    this.user = null;
    this.localStorageService.removeItem('userToken');
    this.languageService.setLanguageDefault();
    this.socketService.disconnect();
  }

  public getStoredUserToken(): string | null {
    return this.localStorageService.getLocalStorage('userToken');
  }

  public getActiveModules(): string[] {
    return this.getActiveModulesAssoc().map(item => item.id);
  }

  public getActiveModulesAssoc(): any[] {
    let modules: { [key: string]: any } = {};
    if (this.user!.rights!['adminuser'] || this.user!.rights!['superuser']) {
      modules = this.customerService.getModules();
    } else {
      const customerModules = this.customerService.getModules();
      modules = Object.keys(this.user!.rights).reduce((acc, key) => {
        if (customerModules[key] && this.user!.rights[key]) {
          acc[key] = this.user!.rights[key];
        }
        return acc;
      }, {} as Record<string, boolean>);
    }

    return Object.entries(modules).filter(item => item).map(([key, value]) => {
      return {id: key, name: this.languageService.getTranslationByKey(key.toUpperCase())};
    });
  }

  public getAdministrativeRights(): string[] {
    let rights: string[]  = ['employee', 'profile'];
    if (this.user!.rights!['settings_users'] || this.user!.rights!['adminuser'] || this.user!.rights!['superuser']) rights.push('settings_users');
    if (this.user!.rights!['settings_groups'] || this.user!.rights!['adminuser'] || this.user!.rights!['superuser']) rights.push('settings_groups');
    if (this.user!.rights!['adminuser'] || this.user!.rights!['superuser']) rights.push('general');
    return rights;
  }

  public toggleIconBar(): void {
    this.iconBarVisible.next(!this.iconBarVisible.value);
  }
}
