<ejs-dialog target=".dialogs" class="steps-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'70%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="false">
  <ng-template #header class="p-1">
    <app-step-header [steps]="steps" [(activeStep)]="activeStep"></app-step-header>
  </ng-template>
  <ng-template #content>
    <div class="d-flex h-100">
      <!-- Left Column -->
      <div class="col-md-4 col-12 d-none d-md-flex flex-column left-container h-100 p-3">
        <div class="text-center mb-4">
          <app-headline [title]="'CALADDEVENT' | translate" [type]="'h5'"></app-headline>
        </div>
        <app-step-content [steps]="steps" [(activeStep)]="activeStep"></app-step-content>
        <div class="save-button-container text-center mt-3 d-none d-md-block">
          <app-form-button [text]="'SAVE' | translate" [disabled]="calendarEventForm.invalid" (click)="save()" [cssClass]="'e-primary w-100'"></app-form-button>
        </div>
      </div>

      <!-- Right Column -->
      <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 right-container h-100 overflow-y-auto p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex align-items-center mb-1">
            <app-headline [title]="steps[activeStep]" [type]="'h5'"></app-headline>
            <div class="ms-2">
              <app-tooltip [icon]="'help'" [iconClass]="'fs-5'" [text]="('CALEVENTGUESTSTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
            </div>
          </div>
          <app-form-button [style]="'btn'" (click)="close()" [cssClass]="'btn-close'"></app-form-button>
        </div>

        <div class="custom-scrollbar overflow-y-auto">
          <form class="p-1 d-flex flex-column h-100">
            <ng-container [ngSwitch]="activeStep">
              <ng-container *ngSwitchCase="0">
                <app-form-label [label]="'CALEVENTNAME' | translate" [maxChars]="35" [currentChars]="calendarEventForm.controls['name'].value.length"></app-form-label>
                  <app-form-input [placeholder]="('CALEVENTNAME' | translate)" [label]="('CALEVENTNAME' | translate)" [errorText]="('CALEVENTNAMEREQUIRED' | translate)" [fCN]="'name'" [fG]="calendarEventForm" [maxChars]="35"></app-form-input>
                <app-form-label [label]="'CALPRIORITYLEVEL' | translate"></app-form-label>
                <div class="mb-3">
                  <app-form-dropdown [fG]="calendarEventForm" [fCN]="'priority'" [dataSource]="priorityOptions" [placeholder]="'SELECT' | translate" [marker]="'priority'"></app-form-dropdown>
                </div>
                <app-form-label [label]="'CALEVENTDESCRIPTION' | translate" [maxChars]="100" [currentChars]="calendarEventForm.controls['description'].value.length"></app-form-label>
                <div class="mb-3">
                  <app-form-textarea [placeholder]="('CALEVENTDESCRIPTION' | translate)" [errorText]="('CALEVENTDESCRIPTIONREQUIRED' | translate)" [fCN]="'description'" [fG]="calendarEventForm" [maxChars]="100"></app-form-textarea>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <app-form-label [label]="'CALEVENTDATE' | translate"></app-form-label>
                <div class="row g-2 mb-3">
                  <div class="col-12 col-md-4">
                    <app-form-date [fG]="calendarEventForm" [fCN]="'dateStart'" [placeholder]="'SELECTDATE' | translate"></app-form-date>
                  </div>

                  <div class="col-12 col-md-8 d-flex align-items-center">
                    <span class="me-2">
                      <app-form-label [type]="'content'" [label]="'CALDATEFROM' | translate"></app-form-label>
                    </span>
                    <app-form-time [fG]="calendarEventForm" [fCN]="'timeStart'"></app-form-time>

                    <span class="mx-2">
                      <app-form-label [type]="'content'" [label]="'CALDATETO' | translate"></app-form-label>
                    </span>
                    <app-form-time [fG]="calendarEventForm" [fCN]="'timeEnd'"></app-form-time>
                  </div>
                </div>

                <div class="row mb-1">
                  <span class="text-secondary fw-bold">
                    <app-form-label [type]="'content'" [label]="'CALFREQUENCY' | translate"></app-form-label>
                  </span>

                  <div class="col-12">
                    <div class="mb-2">
                      <app-form-radio [fG]="calendarEventForm" [fCN]="'frequency'" [value]="'once'" [placeholder]="'CALONLYONCE' | translate"></app-form-radio>
                    </div>

                    <div class="mb-2">
                      <app-form-radio [fG]="calendarEventForm" [fCN]="'frequency'" [value]="'every'" [placeholder]="'CALEVERY' | translate"></app-form-radio>

                      <div class="mt-2 form-check">
                        <div class="d-flex align-items-center mb-3">
                          <div class="col-6 col-md-3 me-2">
                            <app-form-numeric [fG]="calendarEventForm" [fCN]="'everyDays'"></app-form-numeric>
                          </div>
                          <app-form-dropdown [fG]="calendarEventForm" [fCN]="'everyType'" [dataSource]="typeOptions" [placeholder]="'SELECT' | translate"></app-form-dropdown>
                        </div>

                        <div class="d-flex align-items-center">
                          <app-form-dropdown [fG]="calendarEventForm" [fCN]="'everyEnd'" [dataSource]="endOptions" [placeholder]="'SELECT' | translate" [cssClass]="'me-2'"></app-form-dropdown>

                          <ng-container *ngIf="calendarEventForm.controls['everyEnd'].value === 'after'">
                            <div class="col-4 col-md-3 mx-2">
                              <app-form-numeric [fG]="calendarEventForm" [fCN]="'everyRepeatDays'"></app-form-numeric>
                            </div>
                            <app-form-dropdown [fG]="calendarEventForm" [fCN]="'everyRepeatType'" [dataSource]="typeRepeatOptions" [placeholder]="'SELECT' | translate" [cssClass]="'me-2'"></app-form-dropdown>
                          </ng-container>
                          <ng-container *ngIf="calendarEventForm.controls['everyEnd'].value === 'on'">
                            <div class="mx-2">
                              <app-form-date-time [fG]="calendarEventForm" [fCN]="'onDateTimeEnd'" [placeholder]="'SELECTDATE' | translate"></app-form-date-time>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <app-form-button [text]="'GENERATEEVENTS' | translate" [cssClass]="'e-secondary e-small my-1'"></app-form-button>
                    </div>
                  </div>
                </div>

                <!-- Frequency Info Box Section -->
                <div class="row ps-md-3">
                  <div class="form-check col-12">
                    <div class="alert alert-info p-2">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <app-icon-badge [icon]="'info'" [style]="'plainIconSidePadding'"></app-icon-badge>
                          <app-content [style]="'calendarEventCounter'">{{ events.length + ' ' + ('EVENTS' | translate) }}</app-content>
                          <app-content [style]="'small'">{{ ('EVENTSWILLBECREATEDFOR' | translate) }}</app-content>
                        </div>
                      </div>
                      <div class="mt-2 d-flex flex-wrap">
                        <ng-container *ngFor="let event of events | slice:0:(eventsShowAll ? 1000 : 5)">
                          <app-content [style]="'calendarEventDate'">{{ event }}</app-content>
                        </ng-container>
                      </div>
                      <div class="d-flex justify-content-end">
                        <app-form-button [type]="'button'" [text]="((eventsShowAll ? 'SHOWLESS' : 'SHOWMORE') | translate)" [cssClass]="'e-link fs-verysmall p-0'" (click)="eventsShowAll = !eventsShowAll"></app-form-button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Type of Task Section -->
                <div class="row mb-3">
                  <span class="text-secondary fw-bold">
                    <app-form-label [type]="'content'" [label]="'CALTYPEOFEVENT' | translate"></app-form-label>
                  </span>
                  <div class="col-12">
                    <!-- Physical Radio and Input Field -->
                    <div class="d-flex align-items-start" style="height:35px;">
                      <app-form-radio [fG]="calendarEventForm" [fCN]="'type'" [value]="'physical'" [placeholder]="'PHYSICAL' | translate" [cssClass]="'me-3 pt-2'"></app-form-radio>
                      <div class="flex-grow-1" *ngIf="calendarEventForm.controls['type'].value === 'physical'">
                        <app-form-input [fG]="calendarEventForm" [fCN]="'physicalText'" [placeholder]="'PHYSICALTEXT' | translate" ></app-form-input>
                      </div>
                    </div>

                    <!-- Virtual Radio and Input Field -->
                    <div class="d-flex align-items-start" style="height:35px;">
                      <app-form-radio [fG]="calendarEventForm" [fCN]="'type'" [value]="'virtual'" [placeholder]="'VIRTUAL' | translate" [cssClass]="'me-3 pt-2'"></app-form-radio>
                      <div class="flex-grow-1" *ngIf="calendarEventForm.controls['type'].value === 'virtual'">
                        <app-form-input [fG]="calendarEventForm" [fCN]="'virtualText'" [placeholder]="'VIRTUALTEXT' | translate" ></app-form-input>
                      </div>
                    </div>

                    <!-- Video Meeting Radio -->
                    <div class="d-flex align-items-start" style="height:35px;">
                      <app-form-radio [fG]="calendarEventForm" [fCN]="'type'" [value]="'video'" [placeholder]="'VIDEOMEETING' | translate" [cssClass]="'me-3 pt-2'"></app-form-radio>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="2">
                <app-form-user-group-mail [allowEmails]="true" [allowPush]="true" [(user)]="participantsUserIDs" [(groups)]="participantsGroupIDs" (mailsChange)="participantsMails = $event" (pushUserIDsChange)="pushUserIDs = $event" (pushGroupIDsChange)="pushGroupIDs = $event"></app-form-user-group-mail>
              </ng-container>
            </ng-container>
          </form>
        </div>

        <div class="navigation-buttons d-flex justify-content-between mt-3 d-none d-md-flex">
          <app-form-button [text]="'PREV' | translate" *ngIf="activeStep > 0" (click)="activeStep = activeStep - 1" [cssClass]="'e-outline w-10'"></app-form-button>
          <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep < steps.length - 1" (click)="activeStep = activeStep + 1" [cssClass]="'e-secondary ms-auto w-10'"></app-form-button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
      <app-form-button [text]="'PREV' | translate" *ngIf="activeStep > 0" (click)="activeStep = activeStep - 1" [cssClass]="'e-outline w-10 mx-1'"></app-form-button>

      <!-- Dummy button to keep the space for the flex container -->
      <app-form-button *ngIf="activeStep === 0" [text]="'PREV' | translate" [cssClass]="'e-outline invisible w-10 mx-1'"></app-form-button>

      <app-form-button [text]="'SAVE' | translate" [disabled]="calendarEventForm.invalid" (click)="save()" [cssClass]="'e-primary mx-auto w-100'"></app-form-button>

      <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep < steps.length - 1" (click)="activeStep = activeStep + 1" [cssClass]="'e-secondary w-10 mx-1'"></app-form-button>

      <!-- Dummy button to keep the space for the flex container -->
      <app-form-button [text]="'NEXT' | translate" *ngIf="activeStep === steps.length - 1" [cssClass]="'e-secondary invisible w-10 mx-1'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
