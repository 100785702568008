<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="!totalProgress" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="title" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [iconClass]="'fs-5'" [text]="tooltip" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <app-headline [type]="'h6'" [title]="('UPLOADIMAGE' | translate)" [class]="'text-secondary'"></app-headline>
        <div class="dropdown" *ngIf="!totalProgress">
          <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
            <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-calendar-menu">
            <li><app-link (click)="fileInput.click()" [title]="('DOCUMENTSUPLOADIMAGE' | translate)" [style]="'dropdown'">{{ ('DOCUMENTSUPLOADIMAGE' | translate) }}</app-link></li>
            <li><app-link (click)="openCommander($event)" [title]="('DOCUMENTSMYFILES' | translate)" [style]="'dropdown'">{{ ('DOCUMENTSMYFILES' | translate) }}</app-link></li>
          </ul>
        </div>
      </div>
      <div class='fileUploadArea mb-3' (drop)="fileDrop($event)" (dragover)="dragOver($event)">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mx-auto col-12 col-md-6 col-xl-10 justify-content-center">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <app-icon-badge [style]="'documents'"></app-icon-badge>
                  <app-content [style]="'documents'">{{ ('DOCUMENTSDRAGANDDROP' | translate) }}</app-content>
                  <app-form-button (click)="fileInput.click()" [style]="'btn'" [cssClass]="'upload-choose-file btn-link text-secondary fw-bold fs-default px-0 py-1'" [text]="('DOCUMENTSCHOOSEFILE' | translate)"></app-form-button>
                  <input type="file" (change)="fileSelect($event)" hidden #fileInput />
                </div>
                <app-progress *ngIf="totalProgress" [value]="totalProgress"></app-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-scrollbar">
        <div class="d-flex flex-wrap gap-2 uploadedFilesContainer">
          <div *ngFor="let file of files; let i = index" class="uploaded-files-list" (click)="openGallery(i)">
            <img *ngIf="file.preview" [ngSrc]="file.preview" [width]="150" [height]="100" [sizes]="'100vw, 100vw, 100vw'" [alt]="file.filename" priority="low" />
            <img *ngIf="file.type === 'image'" [ngSrc]="file.path!" [width]="150" [height]="100" [sizes]="'100vw, 100vw, 100vw'" [alt]="file.filename" priority="low" />

            <div class="overlay d-flex align-items-center" *ngIf="!totalProgress">
              <app-icon-badge *ngIf="i > 0" [style]="'plainIcon'" [icon]="'chevron_left'" [class]="'mts-icon text-white left-arrow'" (click)="moveFile(i, 'up')"></app-icon-badge>
              <app-icon-badge [style]="'plainIcon'" [icon]="'delete'" [class]="'mts-icon text-white trash-icon'" (click)="showDeleteOverlay(i)"></app-icon-badge>
              <app-icon-badge *ngIf="i < files.length - 1" [style]="'plainIcon'" [icon]="'chevron_right'" [class]="'mts-icon text-white right-arrow'" (click)="moveFile(i, 'down')"></app-icon-badge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" (click)="save()" [disabled]="!!totalProgress" [cssClass]="'e-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>

</ejs-dialog>





