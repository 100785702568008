import {Component, Input, ElementRef, Renderer2, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {LoadingService} from "../../services/loading.service";
import {ButtonModule} from "@syncfusion/ej2-angular-buttons";

@Component({
  selector: 'app-form-button',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    NgIf
  ],
  templateUrl: './form-button.component.html',
  styleUrl: './form-button.component.scss',
})
export class FormButtonComponent implements AfterViewInit {

  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() style: 'e-btn' | 'btn' = 'e-btn';
  @Input() cssClass: string = '';
  @Input() iconClass: string = '';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() isFab: boolean = false;
  @Input() icon: string = '';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() fabPosition: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left' = 'bottom-right';
  @Input() fabTargetContainer: string = ''; // CSS selector for the target container in case of Fab button

  constructor(
    public loadingService: LoadingService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {
    if (this.isFab && this.fabTargetContainer) {
      this.moveFabToTargetContainer();
    }
  }

  private moveFabToTargetContainer(): void {
    // we could take here the parent element, instead of assigning it, but this is more flexible, so that we can pass a dialog as parent...
    const targetContainer = document.querySelector(this.fabTargetContainer);
    if (targetContainer) {
      const fabButtonElement = this.el.nativeElement;
      this.renderer.appendChild(targetContainer, fabButtonElement);
    } else {
      console.error('Target container ' + this.fabTargetContainer + ' not found.');
    }
  }

  public getButtonClass(): string {
    let classes = this.cssClass;
    if (this.isFab) {
      classes += ' e-fab';
      // Add positional classes based on fabPosition
      if (this.fabPosition === 'bottom-right') {
        classes += ' e-fab-bottom e-fab-right';
      } else if (this.fabPosition === 'bottom-left') {
        classes += ' e-fab-bottom e-fab-left';
      } else if (this.fabPosition === 'top-right') {
        classes += ' e-fab-top e-fab-right';
      } else if (this.fabPosition === 'top-left') {
        classes += ' e-fab-top e-fab-left';
      }
    }
    return classes.trim();
  }

 /* // we need to filter passed classes for icons because the size is not getting applied for them
  get iconClasses(): string {
    return [
      ...this.cssClass
        .split(' ')
        .filter((cls) => cls.startsWith('fs-')), // Filter classes starting with "fs-"
      ...this.iconClass.split(' ') //Specific classes for the icon
    ].join(' ');
  }*/
}
