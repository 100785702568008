import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dialog, DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {DialogService} from "../../../shared/services/dialog.service";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {UploaderModule} from "@syncfusion/ej2-angular-inputs";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {LinkComponent} from "../../../shared/elements/link/link.component";
import {ContentComponent} from "../../../shared/elements/content/content.component";
import {ProgressComponent} from "../../../shared/elements/progress/progress.component";
import {DocumentService} from "../../../shared/services/document.service";
import {LanguageService} from "../../../shared/services/language.service";
import {ToasterService} from "../../../shared/services/toaster.service";
import {TYPE} from "../../../shared/types/types";
import {DialogUtility} from "@syncfusion/ej2-popups";

@Component({
  selector: 'app-dialog-documents-upload',
  standalone: true,
  imports: [
    DialogModule,
    FormButtonComponent,
    HeadlineComponent,
    NgForOf,
    NgIf,
    TooltipModule,
    TranslatePipe,
    UploaderModule,
    TooltipComponent,
    IconBadgeComponent,
    LinkComponent,
    ContentComponent,
    ProgressComponent,
    NgOptimizedImage
  ],
  templateUrl: './dialog-documents-upload.component.html',
  styleUrl: './dialog-documents-upload.component.scss'
})
export class DialogDocumentsUploadComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() title: string = '';
  @Input() tooltip: string = '';
  @Input() multiple: boolean = false;
  @Input() allowExtensions: string[] = [];
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() documentsUploaded: EventEmitter<TYPE.Document[]> = new EventEmitter<TYPE.Document[]>();

  public totalProgress: number = 0;
  public files: TYPE.Document[] = [];
  private dialogInstance: Dialog | null = null;

  constructor(
    public dialogService: DialogService,
    private documentService: DocumentService,
    private toasterService: ToasterService,
    private languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public save(): void {
    this.documentsUploaded.emit(this.files.filter((file) => !file.error));
    this.close();
  }

  public openCommander(event: any): void {
    console.log(event);
  }

  private uploadDocuments(documents: FileList): void {
    this.documentService.uploadDocuments('intranet', documents, false , this.documentService.supportedImageTypes).subscribe({
      next: (filesWithStatus: any) => {
        this.files = filesWithStatus;
        this.updateProgress();
      }, error: (error: any) => {
        this.toasterService.error(this.languageService.getTranslationByKey('DOCUMENTS'), this.languageService.getTranslationByKey(error.message));
      }
    });
  }

  public fileSelect(event: any): void {
    const documents = event.target.files as FileList | undefined;
    if (documents !== undefined) {
      this.uploadDocuments(documents);
    }
  }

  public fileDrop(event: DragEvent): void {
    event.preventDefault();
    const documents = event.dataTransfer?.files as FileList | undefined;
    if (documents !== undefined) {
      this.uploadDocuments(documents);
    }
  }

  public dragOver(event: DragEvent): void {
    event.preventDefault();
  }

  public openGallery(index: number): void {

  }

  public moveFile(index: number, direction: 'up' | 'down'): void {
    const file = this.files[index];
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    this.files[index] = this.files[newIndex];
    this.files[newIndex] = file;
  }

  public showDeleteOverlay(index: number): void {
    this.dialogInstance = DialogUtility.confirm({
      title: '<h6 class="text-danger fw-bold m-0 pt-1">' + this.languageService.getTranslationByKey('CONFIRMITEMDELETE') + '</h6>',
      content: this.languageService.getTranslationByKey('AREYOUSUREYOUWANTTODELETE'),
      cancelButton: {
        text: this.languageService.getTranslationByKey('CANCEL'),
        click: () => this.showDeleteOverlayCancel()
      },
      okButton: {
        text: this.languageService.getTranslationByKey('DELETE'),
        click: () => this.showDeleteOverlayConfirmed(index),
        cssClass: 'bg-danger brd-danger'
      },
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X: 'center', Y: 'center'},
      animationSettings: {effect: 'Fade'},
      zIndex: 9999
    });
  }

  private showDeleteOverlayConfirmed(index: number): void {
    this.files.splice(index, 1);
    this.dialogInstance?.hide();
  }

  private showDeleteOverlayCancel(): void {
    this.dialogInstance?.hide();
  }

  private updateProgress(): void {
    this.totalProgress = Math.round(this.files.reduce((total, file) => total + (file.progress ? file.progress : 100), 0) / this.files.length);
    if (this.totalProgress === 100) {
      this.totalProgress = 0;
    }
  }
}
