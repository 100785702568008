import {Component, ElementRef, OnDestroy, OnInit, ViewChild, HostListener} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {ApiService} from "../../shared/services/api.service";
import {RouterService} from "../../shared/services/router.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Config} from "../../shared/config/config";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {ErrorService} from "../../shared/services/error.service";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {LayoutService} from "../../shared/services/layout.service";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {TextBoxModule, NumericTextBoxModule, TextAreaModule, InputEventArgs, ChangedEventArgs} from '@syncfusion/ej2-angular-inputs';
import {TooltipComponent, TooltipModule} from "@syncfusion/ej2-angular-popups";
import { ListViewModule, ListViewComponent } from '@syncfusion/ej2-angular-lists';

import { DialogUtility } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DialogComponent, ResizeDirections } from '@syncfusion/ej2-angular-popups';
import { CommonModule } from '@angular/common';
import { DropDownListModule, FilteringEventArgs, ListBoxModule, ListBoxComponent} from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule, TimePickerModule,DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ButtonModule, RadioButtonModule, CheckBoxModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import {FormCheckboxComponent} from "../../shared/elements/form-checkbox/form-checkbox.component";


setCulture('de-DE');
L10n.load({
  'de-DE': {
    datetimepicker: {
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      hour: 'Stunde',
      minute: 'Minute',
      second: 'Sekunde',
    }
  }
});
@Component({
  selector: 'app-test',
  standalone: true,
    imports: [
        AsyncPipe,
        FormButtonComponent,
        FormInputComponent,
        FormTextareaComponent,
        HeadlineComponent,
        LinkComponent,
        NgIf,
        NgOptimizedImage,
        ReactiveFormsModule,
        TranslatePipe,
        ButtonModule,
        TextBoxModule,
        TooltipModule,
        ListViewModule,
        DialogModule,
        CommonModule,
        DropDownListModule,
        DatePickerModule,
        TimePickerModule,
        NumericTextBoxModule,
        RadioButtonModule,
        DateTimePickerModule,
        CheckBoxModule,
        TextAreaModule,
        FormCheckboxComponent,
        ListBoxModule,
        SwitchModule

    ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup = this.formBuilder.group({
   // customer: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(45)]],
    search_event_string: [''],
    search_user_string: [''],
    external_email: [''],
    rm_1: [''],
    search_link_string: [''],
    mobiles: [''],
    desktops: ['']
  });
  public defaultStoreString = Config.DEFAULT_STORE_STRING;
  private subscriptions: Subscription[] = [];
  showNotifications = false;
  showChatNotifications = false;

  @ViewChild('showEventDialog') showEventDialog: DialogComponent | any;
  @ViewChild('showEventParticipantsDialog') showEventParticipantsDialog: DialogComponent | any;
  @ViewChild('showHomeIconEditDialog') showHomeIconEditDialog: DialogComponent | any;
  @ViewChild('showAllLinksDialog') showAllLinksDialog: DialogComponent | any;
  @ViewChild('showLanguageDialog') showLanguageDialog: DialogComponent | any;
  @ViewChild('showPushSettingseDialog') showPushSettingseDialog: DialogComponent | any;
  @ViewChild('helpTooltip', { static: true }) helpTooltip: TooltipComponent | any;
  @ViewChild('languageSearchTextbox')languageSearchTextbox: any;
  @ViewChild('list') listObj?: ListViewComponent;

  // Data source for the ListBox with a 'selected' property for checkbox state
  public languages: Object =[
      { id: 1, text: 'English', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: true},
      { id: 2, text: 'German', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 3, text: 'French', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 4, text: 'Spanish', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 5, text: 'Romanian', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 6, text: 'Hungarian', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 7, text: 'Portugal', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},
      { id: 8, text: 'Italian', flag: 'https://web.max-toolbox.com/assets/images/flags/de.svg', selected: false},

    ];

  public langiageFields: Object = { text: 'text', id:'id' };



  //New Event Dialog Popup
  @ViewChild('newEventDialog') newEventDialog: DialogComponent | any;
  public position: object={ X: 'center', Y: 'center' };
  public animationSettings: Object = { effect: 'Fade', duration: 400, delay: 0 };
  public resizeHandleDirection: ResizeDirections[] = ['All'];
  public currentStep = 0;
  steps = [
    { title: 'Event Details' },
    { title: 'Date and Location' },
    { title: 'Guests' }
  ];

  events: string[] = [
    'Friday, October 15, 2024',
    'Saturday, October 16, 2024',
    'Sunday, October 17, 2024',
    'Monday, October 18, 2024',
    'Tuesday, October 19, 2024',
    'Wednesday, October 20, 2024',
    'Thursday, October 21, 2024',
    'Friday, October 22, 2024',
    'Saturday, October 23, 2024',
    'Sunday, October 24, 2024'
  ];

  showMore = true;
  totalEvents = this.events.length;
  maxVisible = 3;

  // Selected task type by default
  public taskType: string = 'Virtual';

  // Data sources for dropdowns
  public frequencyTypes: string[] = ['days', 'weeks', 'months'];
  public selectedFrequencyType: string = 'days';
  public endConditions: string[] = ['End after', 'End on', 'Never end'];
  public selectedEndCondition: string = 'End after';
  public eventType: string[] = ['Repeats', 'Days', 'Weeks', 'Months'];
  public selectedEventType: string ='Repeats';
  public priorityTypes: { [key: string]: Object }[] = [
    { text: 'High Priority', id:'high'},
    { text: 'Important', id: 'important'},
    { text: 'Normal', id: 'normal'}
  ];
  public priorityFields: Object = { text: 'text', value: 'id' };
  public selectedPriorityType: string = 'hight';

  public usersAndGroups!: DataManager;
  public userGroupFields: Object = {};
  public userGroupQuery: Query = new Query();

  // Initial Date
  public selectedDate: Date = new Date();
  public selectedStartTime: string = '13:15';
  public selectedEndTime: string = '15:00';

  eventNameValue: string = '';
  eventDescriptionValue: string = '';

  //New link Dialog
  @ViewChild('newLinkDialog') newLinkDialog: DialogComponent | any;
  stepsLink = [
    { title: 'Link Details' },
    { title: 'Link Properties' },
    { title: 'Share Links' }
  ];

  linkNameValue: string = '';
  linkURL: string = '';

  //Small Filter dropdown for Notifications
  public statusData: { [key: string]: Object }[] = [
    { text: 'All', id: 'all'},
    { text: 'News', id: 'news' },
    { text: 'Wiki', id: 'channel' },
    { text: 'Feedback', id: 'survey' },
    { text: 'Workflow', id: 'workflow' }
  ];
  public fields: Object = { text: 'text', value: 'id' };
  public selectedValue: string = 'all';

   //Small Filter dropdown for New Event Filter
   public userFilterData: { [key: string]: Object }[] = [
    { text: 'Show all', id: 'all'},
    { text: 'Show Users only', id: 'users_only' },
    { text: 'Show Groups only', id: 'groups_only' },
    { text: 'Show E-mails only', id: 'emails_only' },
  ];
  public userFilterFields: Object = { text: 'text', value: 'id' };
  public userFilterSelectedValue: string = 'all';

  public tooltipContent = 'This is a html <b>TOOLTIP</b> which is scrollable. Lorem ipsum, in graphical and textual context, refers to filler text that is placed in a document or visual presentation. Lorem ipsum is derived from the Latin “dolorem ipsum” roughly translated as pain itself/ Lorem ipsum presents the sample font and orientation of writing on web pages and other software applications where content is not the main concern of the developer.';
  public tooltipContentForDashBoardIcons = 'Use this panel to customize your home. Check the box next to each module to show or hide its icon, and use the arrow icons to reorder them for a personalized view.';
  public tooltipContentForAllLinks = 'Use this pannel to manage your links and share with other people.';

  //Switch state
  public switchState =  false;

  constructor(
    public loadingService: LoadingService,
    private apiService: ApiService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private userService: UserService,
    public layoutService: LayoutService,
  ) {

  }

  public ngOnInit(): void {
    /*const customerKey = this.customerService.getStoredCustomerKey(this.route.snapshot.params);
    if (customerKey) {
      this.customerService.setCustomerKey(customerKey);
      this.onCustomerLogin(customerKey);
    }*/
    this.usersAndGroups = new DataManager([
      { id: 1, name: 'UFU', isGroup: true },
      { id: 2, name: 'User 1', isGroup: false },
      { id: 3, name: 'Admin', isGroup: true },
      { id: 4, name: 'User 2', isGroup: false }
    ]);
    this.userGroupFields = { text: 'name', value: 'id' };

    // Define an initial query if needed
    this.userGroupQuery = new Query().select(['id', 'name']).take(10).sortBy('isGroup', 'descending').sortBy('name', 'ascending');


  }

  onFiltering(e: FilteringEventArgs): void {
    let query = new Query().select(['id', 'name']);

    // Apply search filter if there is a search input
    query = (e.text !== '') ? query.where('name', 'contains', e.text, true) : query;

    // Update the query in the dropdown
    e.updateData(this.usersAndGroups, query);
  }

  @HostListener('window:resize')
  onResize() {
    if (this.helpTooltip) {
      this.helpTooltip.refresh(); // Refresh tooltip position on window resize
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    window.removeEventListener('resize', this.onResize);
  }

  public onFakeClick(){

  }

  public onFakeMenuOpen(){

  }

  public handleBellCardClick(event: any): void
  {
    // Check if the click occurred inside the dropdown
    if (event.target.closest('.dropdown')) {
      return;
    }

    alert('Notification clicked!');
  }

  public handleChatCardClick(event: any): void
  {
    alert('Chat Notification clicked!');
  }

  public handleRecentCardClick(event: any): void
  {
    // Check if the click occurred inside the dropdown
    if (event.target.closest('.dropdown')) {
      return;
    }

    alert('Recent Notification clicked!');
  }

  // Event handler for notification filter change
  onFilterChange(event: any) {
    console.log('Selected Value:', event.itemData);
    console.log('Selected Text:', event.itemData.text);
    console.log('Selected ID:', event.itemData.id);
  }

  // Event handler for User filter change
  onUserFilterChange(event: any) {
    console.log('Selected Value:', event.itemData);
    console.log('Selected Text:', event.itemData.text);
    console.log('Selected ID:', event.itemData.id);
  }

  // Function to handle task type changes
  onTaskTypeChange(type: string): void {
    this.taskType = type;
  }

  // Initial displayed events (first few)
  get displayedEvents() {
    return this.events.slice(0, this.maxVisible);
  }

  // Hidden events (the rest)
  get hiddenEvents() {
    return this.events.slice(this.maxVisible);
  }

  // Toggle Show More / Show Less
  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  onOpenDialog = (event: any, id:string): void => {
    event.preventDefault();
    if (this.newEventDialog && id == 'new-event') {
      this.currentStep=0;
      this.newEventDialog.show();
    }
    if(this.showEventDialog && id == 'show-event')
    {
      this.showEventDialog.show();
    }
    if(this.showEventParticipantsDialog && id == 'show-event-participants')
    {
      this.showEventParticipantsDialog.show();
    }
    if(this.showHomeIconEditDialog && id == 'show-homeicons-edit')
    {
      this.showHomeIconEditDialog.show();
    }
    if(this.showAllLinksDialog && id == 'show-all-links')
    {
      this.showAllLinksDialog.show();
    }
    if(this.showHomeIconEditDialog && id == 'show-add-link')
    {
      this.showHomeIconEditDialog.show();
    }
    if (this.newLinkDialog && id == 'new-link') {
      this.currentStep=0;
      this.newLinkDialog.show();
    }
    if(this.showLanguageDialog && id == 'show-language')
    {
      this.showLanguageDialog.show();
    }
    if(this.showPushSettingseDialog && id == 'show-notification-settings')
      {
        this.showPushSettingseDialog.show();
      }
  };

  closeDialog(id: string = ""): void {
    if (this.newEventDialog && id == 'new-event') {
      this.newEventDialog.hide();
    }
    if(this.showEventParticipantsDialog && id == 'show-event-participants')
    {
      this.showEventParticipantsDialog.hide();
    }
    if (this.newLinkDialog && id == 'new-link') {
      this.newLinkDialog.hide();
    }
  }

  save(): void {
    this.closeDialog('new-event');
  }

  // Method to go to a specific step in the dialog
  goToStep(stepIndex: number): void {
    this.currentStep = stepIndex;
  }

  // Method to go to the next step
  nextStep(): void {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  // Method to go to the previous step
  prevStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  public onOpenNotififcation = function(event: any): void {
    DialogUtility.alert({
      title: 'Notification settings',
      content: "You’ve updated your notification settings. You may not receive all notifications.<br> To adjust, please visit the Settings menu.",
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X:'center',Y:'center'},
      animationSettings: { effect: 'Fade' }
    });
  }

  public onOpenConfirmDelete = (event: any): void => {
    event.preventDefault();
    DialogUtility.confirm({
      title: '<h6 class="text-danger fw-bold m-0 pt-1">Confirm Event Removal</h6>',
      content: "Are you sure you want to delete this event?",
      cancelButton: {  text: 'Cancel', click: this.cancelClick.bind(this) },
      okButton: {  text: 'DELETE', click: this.deleteClick.bind(this), cssClass: 'bg-danger brd-danger' },
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X:'center',Y:'center'},
      animationSettings: { effect: 'Fade' },
      zIndex:9999
    });
  }

  private deleteClick(): void {
    alert('you clicked DELETE button');
  }

  private cancelClick(): void {
    alert('you clicked CANCEL button');
  }

  toggleNotificationWindow() {
    this.showNotifications = !this.showNotifications;
    this.showChatNotifications = false;
  }

  toggleChatNotificationWindow() {
    this.showChatNotifications = !this.showChatNotifications;
    this.showNotifications = false;
  }

  onEndConditionChange(event: any): void {
    this.selectedEndCondition = event.value;
  }

  // Update the input field value on change
  onInputChange(args: InputEventArgs): void {
    this.eventNameValue = args.value || '';
    this.linkNameValue = args.value || '';
  }

  onTextareaChange(args: InputEventArgs): void {
    this.eventDescriptionValue = args.value || '';
  }

  onShareEvent(): void{
    this.onOpenDialog(event, 'new-event');
    this.goToStep(2);

  }

  toggleSelection(item: any): void {
    item.selected = !item.selected;
    //this.homeReorderListBox.refresh();
  }

  onkeyup(event: any){
    let value = this.languageSearchTextbox.nativeElement.value;
    let data = new DataManager(this.languages).executeLocal(new Query().where("text", "startswith", value, true));

    if (!value) {
      (this.listObj as ListViewComponent).dataSource = (this.languages as any).slice();
    } else {
      ((this.listObj as ListViewComponent).dataSource as any) = data;
    }
    this.listObj?.dataBind();
}

  /*moveUp(data: any) {
    const items = this.iconsListOrder.dataSource.json as Array<any>;
    const index = items.findIndex(item => item.id === data.id);
    if (index > 0) {
      [items[index - 1], items[index]] = [items[index], items[index - 1]];
      this.updateDataManager();
    }
  }

  moveDown(data: any) {
    const items = this.iconsListOrder.dataSource.json as Array<any>;
    const index = items.findIndex(item => item.id === data.id);

    if (index < items.length - 1) {
      [items[index], items[index + 1]] = [items[index + 1], items[index]];
      this.updateDataManager();
    }
  }


  updateDataManager() {
    //this.homeReorderListBox.dataSource = this.iconsListOrder;
   // this.homeReorderListBox.refresh();
    console.log('Final Order:', this.iconsListOrder.dataSource.json);
  }*/

  public onCustomerLogin(customerKey: string | null): void {
    if (customerKey) {
      this.subscriptions.push(this.apiService.getCustomer(customerKey).subscribe({
        next: (customer) => {
          this.customerService.setCustomer(customer);
          this.languageService.setCustomerLanguages(customer.languages);

          if (customer.api && typeof customer.api != null) {
            this.apiService.setUrl(customer.api);
          }

          if (this.route.snapshot.params.hasOwnProperty('token')) {
            this.userService.setToken(this.route.snapshot.params['token']);
          }
          this.routerService.navigateTo('/auth/login');
        }, error: (error) => {
          this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, false);
        }
      }));
    }
  }

  public setLanguage(language: string): void {
    this.languageService.setLanguage(language);
  }
}
