import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import {DialogService} from "../../services/dialog.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [
    TooltipModule,
    NgIf
  ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {
  @Input() text: string = '';
  @Input() class: string = '';
  @Input() iconClass: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() icon: string = '';
  @Input() opensOn: 'Click' | 'Hover' = 'Click';
  @Input() position: 'TopLeft' | 'TopCenter' | 'TopRight' | 'BottomLeft' | 'BottomCenter' | 'BottomRight' | 'LeftTop' | 'LeftCenter' | 'LeftBottom' | 'RightTop' | 'RightCenter' | 'RightBottom' = 'TopCenter'
  @Input() cssClass: string = '';

  constructor(
    public dialogService: DialogService
  ) {
  }
}
