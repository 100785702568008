<ng-container *ngIf="style === 'default'">
  <p [class]="cssClass"><small><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></small></p>
</ng-container>

<ng-container *ngIf="style === 'notification'">
  <p [class]="'fs-verysmall text-muted mb-1 ' + cssClass" [innerHTML]="innerHTML"></p>
</ng-container>

<ng-container *ngIf="style === 'notificationDate'">
  <p [class]="'mb-0 text-muted fs-verysmall fst-italic ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></p>
</ng-container>

<ng-container *ngIf="style === 'calendarEventCounter'">
  <p [class]="'mb-0 fw-bold fs-verysmall me-1 ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></p>
</ng-container>

<ng-container *ngIf="style === 'calendarEventDate'">
  <p [class]="'mb-0 badge bg-light text-primary mb-1 me-1 fw-normal ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></p>
</ng-container>

<ng-container *ngIf="style === 'calendarEventItem'">
  <p [class]="'mb-0 text-muted fs-small ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></p>
</ng-container>

<ng-container *ngIf="style === 'small'">
  <p [class]="'mb-0 fs-verysmall ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></p>
</ng-container>

<ng-container *ngIf="style === 'userGroupMail'">
  <span [class]="'text-light fs-verysmall ' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></span>
</ng-container>

<ng-container *ngIf="style === 'documents'">
  <span [class]="'upload-text fs-6 text-secondary' + cssClass"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></span>
</ng-container>


<ng-template #linkTemplate><ng-content></ng-content></ng-template>
