<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'40vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('MANAGECREDENTIALS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [iconClass]="'fs-5'" [text]="('MANAGECREDENTIALSTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column col-12 mx-auto justify-content-center pt-4 p-3">
      <div class="row mb-2">
        <div class="col-md-12">
          <app-form-label [label]="'USERNAME' | translate"></app-form-label>
          <app-form-input [placeholder]="('USERNAME' | translate)" [errorText]="('USERNAMEREQUIRED' | translate)" [fCN]="'user_name'" [fG]="fG" [maxChars]="255"></app-form-input>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <app-form-label [label]="'PASSWORD' | translate"></app-form-label>
          <app-form-input [type]="showPassword ? 'text' : 'password'" [placeholder]="('PASSWORD' | translate)" [errorText]="('PASSWORDREQUIRED' | translate)" [fCN]="'password'" [fG]="fG" [iconBack]="showPassword ? 'visibility_off' : 'visibility'" [iconClickType]="'back'" [maxChars]="191" (iconBackClick)="showPassword = !showPassword"></app-form-input>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" [disabled]="fG.invalid" (click)="save()" [cssClass]="'btn btn-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
