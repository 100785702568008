import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IconBadgeComponent} from "../icon-badge/icon-badge.component";
import {DropDownListModule, SortOrder} from "@syncfusion/ej2-angular-dropdowns";
import {FieldSettingsModel, FilteringEventArgs, SelectEventArgs} from "@syncfusion/ej2-dropdowns";
import {NgIf} from "@angular/common";
import {TYPE} from "../../types/types";

@Component({
  selector: 'app-form-dropdown',
  standalone: true,
  imports: [
    IconBadgeComponent,
    DropDownListModule,
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './form-dropdown.component.html',
  styleUrl: './form-dropdown.component.scss'
})
export class FormDropdownComponent implements OnInit, OnChanges {
  @Input() fCN: string = '';
  @Input() dataSource: any[] = [];
  @Input() mapping: FieldSettingsModel = {text: 'name', value: 'id'};
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() placeholder: string = '';
  @Input() icon: string = '';
  @Input() sortOrder: SortOrder = 'None';
  @Input() marker: 'priority' | null = null;
  @Input() withEmpty: boolean = false;
  @Input() allowFiltering: boolean = false;
  @Input() returnObject: boolean = false;
  @Input() popupWidth: string = '100%';
  @Output() valueChange = new EventEmitter<string|number|TYPE.FieldSettingsModelWithId>();
  @Output() reachedEndOfList = new EventEmitter<void>();
  @Output() search = new EventEmitter<string>();

  public constructor() {
  }

  public ngOnInit(): void {
    // Add empty option if needed
    if (this.withEmpty) {
      this.dataSource.unshift({id: '', name: this.placeholder});
    }

    // Disable the dropdown if needed
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public onChange(event: any): void {
    const tmp: TYPE.FieldSettingsModelWithId = event.itemData as TYPE.FieldSettingsModelWithId;
    if (tmp && tmp.id) {
      if (this.returnObject) {
        this.valueChange.emit(tmp);
      } else {
        this.valueChange.emit(tmp.id);
      }
    }
  }

  public filter(event: FilteringEventArgs): void {
    event.preventDefaultAction = true;
    event.cancel = true;
    this.search.emit(event.text);
  }

  public checkEndOfList(event: any): void {
    let popupElement = event.popup.element.querySelector('div.e-content.e-dropdownbase');
    popupElement.addEventListener('scroll', () => {
      const {scrollTop, scrollHeight, clientHeight} = popupElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.reachedEndOfList.emit();
      }
    });
  }
}
