import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {DatePipe, NgForOf, NgIf, NgOptimizedImage, SlicePipe} from "@angular/common";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {IconBadgeComponent} from "../../shared/elements/icon-badge/icon-badge.component";
import {BarTopComponent} from "../helper/bar-top/bar-top.component";
import {BarFooterComponent} from "../helper/bar-footer/bar-footer.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../shared/services/api.service";
import {TYPE} from "../../shared/types/types";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {WidgetCalendarComponent} from "../calendar/widget-calendar/widget-calendar.component";
import {NotificationComponent} from "../../shared/elements/notification/notification.component";
import {ErrorService} from "../../shared/services/error.service";
import {DialogHomeLinkComponent} from "./dialog-home-link/dialog-home-link.component";
import {DialogHomeLinkManagerComponent} from "./dialog-home-link-manager/dialog-home-link-manager.component";
import {DialogHomeIconsComponent} from "./dialog-home-icons/dialog-home-icons.component";
import {RouterService} from "../../shared/services/router.service";
import {TooltipComponent} from "../../shared/elements/tooltip/tooltip.component";
import {LayoutService} from "../../shared/services/layout.service";
import {userGuard} from "../../shared/guards/user.guard";


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BarTopComponent,
    TranslatePipe,
    NgOptimizedImage,
    HeadlineComponent,
    DatePipe,
    IconBadgeComponent,
    BarFooterComponent,
    NgIf,
    LinkComponent,
    NgForOf,
    WidgetCalendarComponent,
    NotificationComponent,
    DialogHomeLinkComponent,
    DialogHomeLinkManagerComponent,
    DialogHomeIconsComponent,
    TooltipComponent,
    SlicePipe
  ],
  providers: [
    TranslatePipe
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {

  public page: number = 1;
  public pageLinks: number = 1;
  public iconsAllowed: string[] = [];
  public iconsPageOne: string[] = [];
  public iconsPageTwo: string[] = [];
  public iconsBadges: TYPE.HomeCounter = {} as TYPE.HomeCounter;
  public homeIconsDialog: boolean = false;
  public homeLinkDialog: boolean = false;
  public homeLinkManagerDialog: boolean = false;
  public latestNotification: TYPE.Notification = {} as TYPE.Notification;
  public currentDate: Date = new Date();
  private subscriptions: Subscription[] = [];
  public showCalendarWidget: boolean = true;  // TODO: Implement option in settings to show or hide the event calendar
  public maxLinksPerPage: number = 8;

  constructor(
    public loadingService: LoadingService,
    public customerService: CustomerService,
    public languageService: LanguageService,
    public userService: UserService,
    public routerService: RouterService,
    private apiService: ApiService,
    private layoutService: LayoutService,
    private errorService: ErrorService
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.apiService.getHomeCounter().subscribe((homeCounter) => {
      this.iconsBadges = homeCounter;
    }));

    this.iconsAllowed = this.userService.getActiveModules().concat(this.userService.getAdministrativeRights());
    this.iconsPageOne = this.iconsAllowed.slice(0, 8);
    this.iconsPageTwo = this.iconsAllowed.slice(8, 16);
    this.load();

    this.layoutService.breakpointWidthType.subscribe((breakpoint) => {
      this.maxLinksPerPage = 8;
      if (breakpoint === 'xs' || breakpoint === 'sm') {
        this.maxLinksPerPage = 4;
      }
      if (this.pageLinks > this.getPageLinksNumbers().length) {
        this.pageLinks = this.getPageLinksNumbers().length;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public load(): void {
    this.subscriptions.push(this.apiService.getBell(0, 'new', null).subscribe({
      next: (messages) => {
        if (messages.length) {
          this.latestNotification = messages[0];
        }
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }

  public openLink(link: TYPE.Link): void {
    if (link.is_external) {
      this.routerService.openURL(link.value);
    } else {
      this.routerService.navigateTo(link.value);
    }
  }

  public getPageLinksNumbers(): number[] {
    return Array.from(Array(Math.ceil(this.userService.getUser()!.external_links.length / this.maxLinksPerPage)).keys());
  }

  public getPageLinksPlaceHolder(): number[] {
    if (this.pageLinks === this.getPageLinksNumbers().length) {
      const missing = this.maxLinksPerPage - (this.userService.getUser()!.external_links.length % this.maxLinksPerPage || this.maxLinksPerPage);
      return Array.from({ length: missing }, (_, index) => index);
    }
    return [];
  }
}
