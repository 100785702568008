<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('CALALLEVENTS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [iconClass]="'fs-5'" [text]="('CALEVENTGUESTSTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">
      <app-headline [title]="('CALNOEVENTSAVAILABLE' | translate)" [type]="'h6'" [class]="'text-center text-secondary'"></app-headline>
      <app-form-input type="search" [placeholder]="('SEARCHFOREVENT' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'searchString'" [fG]="calendarEventsForm"></app-form-input>
      <app-headline [title]="('CALNOEVENTSFOUND' | translate)" [type]="'h6'" [class]="'text-center text-secondary'"></app-headline>

      <!-- Scrollable content-->
      <div class="custom-scrollbar overflow-y-auto h-100">
        <ng-container *ngFor="let calenderEvent of calenderEvents">
          <app-item-calendar-event [calenderEvent]="calenderEvent" (edit)="edit($event)" (participants)="participants($event)"></app-item-calendar-event>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ejs-dialog>


<app-dialog-calendar-event *ngIf="calenderEventToEdit" [(visible)]="calendarEventDialog" [calendarEvent]="calenderEventToEdit"></app-dialog-calendar-event>
<app-dialog-calendar-participants *ngIf="calenderEventToEdit" [(visible)]="calendarParticipantsDialog" [calenderEvent]="calenderEventToEdit"></app-dialog-calendar-participants>
