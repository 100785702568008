import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DialogService} from "../../../shared/services/dialog.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../shared/services/user.service";
import {NgForOf, NgIf} from "@angular/common";
import {FormCheckboxComponent} from "../../../shared/elements/form-checkbox/form-checkbox.component";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {RouterService} from "../../../shared/services/router.service";

@Component({
  selector: 'app-dialog-home-icons',
  standalone: true,
  imports: [
    DialogModule,
    HeadlineComponent,
    TooltipComponent,
    TranslatePipe,
    TooltipModule,
    NgForOf,
    FormCheckboxComponent,
    IconBadgeComponent,
    NgIf,
    FormButtonComponent
  ],
  templateUrl: './dialog-home-icons.component.html',
  styleUrl: './dialog-home-icons.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class DialogHomeIconsComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public iconsAllowed: string[] = [];
  public iconsForm: FormGroup = this.formBuilder.group({
  });

  constructor(
    public dialogService: DialogService,
    public routerService: RouterService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.iconsAllowed = this.userService.getActiveModules().concat(this.userService.getAdministrativeRights());
    this.iconsAllowed.forEach(icon => {
      this.iconsForm.addControl(icon, this.formBuilder.control(true));
    });
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public moveIcon(direction: string, position: number, icon: string): void {
    if (this.iconsAllowed.indexOf(icon) === position) {
      if (direction === 'up') {
        this.iconsAllowed[position] = this.iconsAllowed[position - 1];
        this.iconsAllowed[position - 1] = icon;
      }
      if (direction === 'down') {
        this.iconsAllowed[position] = this.iconsAllowed[position + 1];
        this.iconsAllowed[position + 1] = icon;
      }
    }
  }

  public save(): void {
    // TODO: Implement save method

    this.visibleChange.emit(false);
  }
}
