<ng-container *ngIf="style === 'home'">
  <div class="dash-icon {{ color ? color : '' }} d-flex flex-column align-items-center">
    <div class="icon-container {{ orientation === 'bottomRight' ? 'bottom-right' : orientation === 'bottomLeft' ? 'bottom-left' : orientation === 'topRight' ? 'top-right' : 'top-left' }} position-relative">
      <div class="background-layer"></div>
      <div class="foreground-layer">
        <div class="icon">
          <span class="mts-icon">{{ icon }}</span>
        </div>
      </div>
      <span *ngIf="badge" class="e-badge e-badge-primary e-badge-overlap e-badge-notification">{{ badge }}</span>
    </div>
    <div class="icon-text text-center">{{ title }}</div>
  </div>
</ng-container>

<ng-container *ngIf="style === 'homeIcons'">
  <span class="mts-icon icon-size {{ color ? 'text-' + color : '' }}">{{ icon }}</span>
</ng-container>

<ng-container *ngIf="style === 'barTop'">
  <button type="button" class="btn btn-link p-1 text-muted position-relative">
    <span class="mts-icon fs-4 {{ danger ? 'text-danger' : '' }}">{{ icon }}</span>
    <span *ngIf="alert" class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
      <span class="visually-hidden">{{ screenReaderText }}</span>
    </span>
  </button>
</ng-container>

<ng-container *ngIf="style === 'plainIcon'">
  <span class="mts-icon p-m">{{ icon }}</span>
</ng-container>

<ng-container *ngIf="style === 'plainIconSidePadding'">
  <span class="mts-icon px-1">{{ icon }}</span>
</ng-container>

<ng-container *ngIf="style === 'link'">
  <span class="mts-icon lh-1">{{ icon }}</span>
</ng-container>

<ng-container *ngIf="style === 'notification'">
  <button class="btn btn-link p-0 text-muted">
    <span class="mts-icon fs-5">{{ icon }}</span>
    <span class="visually-hidden">{{ screenReaderText }}</span>
  </button>
</ng-container>

<ng-container *ngIf="style === 'documents'">
  <span class="upload-icon fs-1 mts-icon text-secondary me-3">cloud_upload</span>
</ng-container>
