<div class="icon-bar d-none d-md-flex flex-column align-items-center position-fixed border-top border-end border-bottom shadow p-m" *ngIf="iconBarVisible">
  <ng-container *ngFor="let icon of iconData">
    <ng-container *ngIf="iconsAllowed.includes(icon.key)">
      <app-tooltip
        [text]="(icon.textKey | translate)"
        [opensOn]="'Hover'"
        [position]="'RightCenter'">
        <app-form-button
          [style]="'btn'"
          [cssClass]="'btn-link p-m'"
          [iconClass]="icon.iconClass"
          [icon]="icon.icon"
          (click)="routerService.navigateTo(icon.route)">
        </app-form-button>
      </app-tooltip>
    </ng-container>
  </ng-container>
</div>
